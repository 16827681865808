import React, { useMemo } from "react";
import { BlockPost } from "../../types/Block";
import { getBlockElement } from "../../utils/sectionUtils";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";
import { useNavigate } from "react-router-dom";

interface Props {
  data?: BlockPost;
}

export default function Post(props: Props): React.JSX.Element {
  const { data } = props;
  const navigate = useNavigate();

  const image = useMemo(() => {
    if (data?.image && data?.image?.xs?.url) {
      if (data?.variant === "primary") {
        return <ResponsiveImage imageValues={data?.image} />;
        //return <img width={"100%"} src={data?.image.url} />;
      } else {
        return (
          <>
            <ResponsiveImage
              imageValues={data?.image}
              className={"w-full md:hidden"}
            />
            <div
              className={[
                "hidden md:inline  bg-cover",
                "bg-center",
                data?.variant === "secondary"
                  ? "w-[33%] w-max-[33%]"
                  : "w-[50%] w-max-[50%]",
              ].join(" ")}
              style={{
                backgroundImage: `url('${data?.image.xs.url}')`,
                minWidth: data?.variant === "secondary" ? "33%" : "50%",
              }}
            />
          </>
        );
        // return <div className={cN} style={{backgroundImage: `url(${data.image.url})`}} />
      }
    }
    return null;
  }, [data, data?.variant]);

  const wrapperClass = useMemo(() => {
    const variant = () => {
      switch (data?.variant) {
        case "primary":
          return "flex-col";
        case "secondary":
          return "flex-row";
        default:
          return "flex-col md:flex-row";
      }
    };

    return [
      "flex",
      "w-full",
      "gap-2",
      "bg-black/[.8] rounded-xl overflow-hidden",
      // "border border-gray-900",
      "max-w-screen-md",
      "shadow-gray-700 shadow-sm",
      data?.to ? "cursor-pointer" : "",
      variant(),
    ].join(" ");
  }, [data]);

  const contentClass = useMemo(() => {
    const variant = () => {
      switch (data?.variant) {
        case "primary":
          return "w-full";
        case "secondary":
        default:
          return "flex-grow";
      }
    };
    return ["content flex flex-col", "p-4", "gap-2", variant()].join(" ");
  }, [data]);

  return (
    <div className={"post flex w-full mb-8 justify-center px-2"}>
      <div
        className={wrapperClass}
        onClick={data?.to ? () => navigate(`/${data?.to}`) : undefined}
      >
        {image}
        <div className={contentClass}>
          {data?.subtitle && (
            <h5 className={"text-2xl pt-0"}>{data?.subtitle}</h5>
          )}
          <h3 className={"text-3xl md:text-5xl pb-0 mb-0 uppercase"}>
            {data?.title}
          </h3>
          {data?.text && (
            <div
              className={
                "flex font-thin text-2xl md:text-4xl w-full py-2 flex-col gap-2"
              }
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          )}
          {data?.action && (
            <div className={"flex w-full flex-col"}>
              {data?.action.map((block, idx: number) => {
                return getBlockElement(block, idx, "post");
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
