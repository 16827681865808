import React, { PropsWithChildren } from "react";

export default function Home(props: PropsWithChildren): React.JSX.Element {
  const h1 = [
    "flex flex-col lg:flex-row flex-wrap justify-center items-center",
    "font-bold text-center",
    "text-4xl sm:text-7xl lg:text-7xl",
    "mt-32 lg:mt-4",
    "text-black",
    "leading-8 uppercase",
  ].join(" ");

  const nuSpan = [
    "inline",
    "w-fit",
    "bg-white",
    "px-1 pt-1 pb-0",
    "text-6xl sm:text-8xl lg:text-7xl",
  ].join(" ");

  const sepSpan = ["hidden", "lg:inline", "bg-white", "p-0"].join(" ");

  const claimSpan = [
    "bg-white",
    "px-1 pt-1 pb-0",
    "mt-[-0.5rem] sm:mt-[-1rem] lg:mt-0",
  ].join(" ");

  const url = [
    "text-2xl uppercase",
    "flex w-fit self-center",
    "text-center",
    "mt-0 sm:mt-[-1rem]",
    "tracking-widest",
    "bg-white",
    "text-black",
    "px-1 pt-1 pb-0",
  ].join(" ");

  return (
    <div
      id="home"
      className={[
        "home flex flex-col",
        "w-full h-full",
        "lg:pb-[12rem]",
        "justify-center md:justify-end lg:justify-between",
        "gap-6",
        "pointer-events-none",
        "animate-fade-in fill-mode-forwards",
      ].join(" ")}
    >
      <div className={"flex flex-col justify-center"}>
        <h1 className={h1}>
          <span className={nuSpan}>nu</span>
          <span className={sepSpan}> - </span>
          <span className={claimSpan}>a tribute to nu-metal</span>
        </h1>
        <h5 className={url}>best-of-nu-metal.de</h5>
      </div>
      <div
        className={
          "w-full px-[2rem] sm:px-[8rem] flex self-center mb-[10rem] lg:mb-[0]"
        }
      >
        {props.children}
      </div>
    </div>
  );
}
