import React, { useEffect, useMemo, useState } from "react";
import {
  IKirbyAsset,
  IKirbyIcon,
  useAppState,
  useAsset,
  useIcon,
  useNavigation,
} from "@gk-lab/kirbyreact";
import Button from "../Button/Button";
import { useHash } from "../../hooks/useHash";
import { Link } from "react-router-dom";
import { scrollBodyToElement } from "../../utils/utils";
import logo from "../../icons/more_vert.svg";

export default function Navigation(): React.JSX.Element {
  const [showMenu, setShowMenu] = useState(false);
  const [init, setInit] = useState(true);

  const menuLogo: IKirbyAsset | undefined = useAsset("menu-logo");
  const fb: IKirbyIcon | undefined = useIcon("facebook");
  const insta: IKirbyIcon | undefined = useIcon("instagram");

  const { items } = useNavigation();
  const { facebook, instagram } = useAppState();

  const { hash, location } = useHash();

  useEffect(() => {
    if (init) {
      const t = setTimeout(() => {
        setInit(false);
      }, 1000);
      return () => {
        clearTimeout(t);
      };
    }
  }, [init]);

  const children = useMemo(() => {
    const home = location.pathname.indexOf("/home") === 0;

    return items?.map((item, idx) => {
      const isActive = item.anchor === `#${hash}`;
      const dur = 50;
      const d = dur * idx;

      const cN = [
        "self-end",
        "transition-all ease-in-out duration-[0.8s]",
      ].join(" ");

      return (
        <Button
          data={{ ...item, variant: "navigation" }}
          key={`${item.to}-${item.anchor}`}
          active={isActive || (!hash && idx === 0)}
          className={cN}
          style={{
            transitionDelay: `${d}ms`,
            marginBottom: !init && home ? "0" : "-16rem",
          }}
          onClick={() => {
            if (item?.anchor) {
              scrollBodyToElement(
                `section-${item?.anchor.replace("#", "")}`,
                true
              );
            }
          }}
        />
      );
    });
  }, [items, hash, init, location.pathname]);

  const childrenXs = useMemo(() => {
    const l = items?.length ?? 10;
    const dur = 50;
    return items?.map((item, idx) => {
      const isActive = item.anchor === `#${hash}`;
      const d = showMenu ? l * dur - idx * dur : idx * dur;
      const cN = [
        "self-end",
        showMenu ? "mr-0" : "mr-[-150%]",
        "transition-all ease-in-out duration-[0.8s]",
      ].join(" ");

      return (
        <Button
          data={{ ...item, variant: "navigation" }}
          key={`${item.to}-${item.anchor}`}
          style={{ transitionDelay: `${d}ms` }}
          className={cN}
          active={isActive}
          onClick={() => {
            if (item?.anchor) {
              scrollBodyToElement(
                `section-${item?.anchor.replace("#", "")}`,
                true
              );
            }
            setShowMenu(false);
          }}
        />
      );
    });
  }, [items, hash, showMenu]);

  const logoCn = useMemo(() => {
    const m = location.pathname.indexOf("/home") === 0;
    const isOnTop =
      !hash || (items && items[0] && `#${hash}` === items[0].anchor);

    let b = "";
    if (!init) {
      if (isOnTop && m) {
        // home und ganz oben
        b = "ml-0 pb-[6rem] lg:pb-[10rem]";
      } else if (m) {
        // home
        b = "ml-0 pb-[3rem]";
      } else {
        // Unterseite
        b = "ml-0 pb-[1rem]";
      }
    } else {
      // initial
      b = "ml-[-20rem]";
    }

    return [
      "flex flex-col",
      b,
      "w-fit h-full",
      "justify-end",
      "transition-all ease-out duration-[0.8s]",
    ].join(" ");
  }, [location.pathname, init, hash, items]);

  const nav = useMemo(() => {
    // const home = location.pathname.indexOf("/home") === 0;
    const isOnTop =
      !hash || (items && items[0] && `#${hash}` === items[0].anchor);
    let b = "";
    if (!init) {
      if (isOnTop) {
        b = "bottom-32";
      } else {
        b = "bottom-8";
      }
    } else {
      b = "bottom-32";
    }

    return [
      "absolute",
      "hidden lg:flex flex-row gap-2 lg:gap-4 xl:gap-6",
      "left-0 right-0",
      "mx-auto",
      "justify-center",
      b,
      "transition-all ease-in-out duration-[0.4s]",
    ].join(" ");
  }, [init, items, hash]);

  const navXs = useMemo(() => {
    const home = location.pathname.indexOf("/home") === 0;
    const isOnTop =
      !hash || (items && items[0] && `#${hash}` === items[0].anchor);
    let b = "";
    if (!init) {
      if (isOnTop) {
        b = "bottom-24";
      } else {
        b = "bottom-12";
      }
    } else {
      b = "bottom-24";
    }

    return [
      "absolute flex lg:hidden flex-col gap-4 justify-end",
      "landscape:hidden",
      home ? "right-4" : "right-[-16rem]",
      b,
      "transition-all ease-in-out duration-[0.4s]",
    ].join(" ");
  }, [location.pathname, hash, items, init]);

  const menuBtn = useMemo(() => {
    return [
      "rounded-[50%]",
      showMenu ? "bg-red-700" : "bg-white hover:bg-white animate-pulse",
      "w-12 h-12 md:w-16 md:h-16",
      "pointer-events-auto",
      "self-end",
    ].join(" ");
  }, [showMenu]);

  const socials = useMemo(() => {
    const home = location.pathname.indexOf("/home") === 0;
    return [
      "socials",
      "absolute right-2 md:right-4",
      home ? "top-2 md:top-4" : "top-[-6rem]",
      "flex flex-row",
      "gap-1 md:gap-0",
      "pointer-events-auto",
      "transition-all ease-in-out duration-[0.4s]",
    ].join(" ");
  }, [location.pathname]);

  const socialBtn = useMemo(() => {
    return ["w-12 h-12", "md:w-16 md:h-16"].join(" ");
  }, []);

  return (
    <div
      className={
        "navigation w-screen max-w-screen-2xl h-screen max-h-screen z-20 overflow-hidden fixed left-0 right-0 top-0 bottom-0 pointer-events-none mx-auto"
      }
    >
      <div className={socials}>
        {facebook && (
          <Link className={"fb"} to={facebook} target={"_blank"}>
            <img className={socialBtn} src={fb?.url} alt={"facebook"} />
          </Link>
        )}
        {instagram && (
          <Link className={"insta"} to={instagram} target={"_blank"}>
            <img className={socialBtn} src={insta?.url} alt={"instagram"} />
          </Link>
        )}
      </div>

      <div className={logoCn}>
        <img
          className={
            "w-[20vw] h-[20vw] min-w-[120px] min-h-[120px] max-w-[240px] max-h-[240px]"
          }
          alt={"nu-logo"}
          src={menuLogo?.image.xs.url}
        />
      </div>

      <div
        className={
          "absolute left-0 bottom-1 right-0 flex flex-col items-center"
        }
      >
        <nav className={"flex flex-col gap-2"}>
          <div className={nav}>{children}</div>
          <div className={navXs}>
            {childrenXs}
            <button
              className={menuBtn}
              onClick={() => setShowMenu((oldValue) => !oldValue)}
            >
              <img
                className={`icon-menu w-full ${showMenu ? "active" : ""}`}
                src={logo}
                alt={"menu"}
              />
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}
